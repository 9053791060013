import React from "react"
import Layout from "../../layouts/default"
import { useStaticQuery, graphql } from "gatsby"

import AttributionLiland from "../../content/shared/attribution-event-liland"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import StepsBox from "../../components/steps-box"
import VideoPlayer from "../../components/video-player"

const EinfuehrungDGS = () => {
  const data = useStaticQuery(graphql`
    query EinfuehrungDGS {
      poster: file(relativePath: { eq: "videos/poster-dgs.jpg" }) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="workshop-dgs">
      <Seo
        title="Einführung in die Gebärdensprache"
        description="In einem Video-Vortrag gewährt die taube Linguistin und Gebärdensprachdolmetscherin Dr. Daniela Happ einen Einblick in die Feinheiten der Deutschen Gebärdensprache"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Einführung in die Gebärdensprache",
              link: "/workshops/einfuehrung-dgs",
            },
          ]}
        />
        <PageTitle>Einführung in die Gebärdensprache</PageTitle>
        <Paragraph dropcap={true}>
          Hölderlin hat aus dem großen Schatz von Worten, Silben und Sätzen der
          deutschen Sprache seine Gedichte geformt. Einige dieser Gedichte
          wurden für die inklusive Sonderausstellung ›Hölderlin liebt…‹ in
          Deutsche Gebärdensprache adaptiert. Doch gibt es in der
          Gebärdensprache überhaupt Silben, Wörter und Sätze? Woraus bestehen
          sie? Wie werden sie gebildet? In einem Video-Vortrag gewährt die taube
          Linguistin und Gebärdensprachdolmetscherin Dr. Daniela Happ einen
          Einblick in die Feinheiten der Deutschen Gebärdensprache.
        </Paragraph>

        <Stack space={3}>
          <Heading as="h2" level={3}>
            Eine kleine Einführung in die Gebärdensprache
          </Heading>
          <Paragraph as="span">von Dr. Daniela Happ</Paragraph>
        </Stack>
        <VideoPlayer src="/einfuehrung-dgs" poster={data.poster} />

        <Constrain>
          <Stack>
            <Paragraph>
              Was ist Gebärdensprache? Eine Sprache natürlich. Genau wie
              Lautsprache, wie Japanisch, Französisch oder jede beliebige
              Sprache. Sie hat nur eine andere Ausdrucksform. Lautsprache wird
              durch Mund, Lippen, Kehlkopf, die Öffnung der Stimmbänder und die
              dort hindurchströmende Luft wahrnehmbar, das Gehirn kann diese
              Laute als Sprache verarbeiten. Bei Gebärdensprache müssen Sie sich
              vorstellen, dass die eben beschriebenen Artikulationsorgane
              sozusagen nach außen gestülpt sind. Die Bewegung der Hände kann
              man mit dem durch die Stimmbänder hindurchströmenden Luftstrom
              vergleichen. Die Handformen, also wie ich hier als Beispiel
              angebe, kann man sich vorstellen wie die Zunge, die an die Zähne
              stößt oder welche Form die Zunge annimmt. Die Mimik beim Gebärden
              ist vergleichbar dem Tonfall oder Ausdruck, der Intonation beim
              Sprechen. So in etwa funktioniert Gebärdensprache.
            </Paragraph>

            <Paragraph>
              Oft fragt man mich, ob Gebärdensprache international ist, sodass
              man sich überall auf der Welt damit verständigen kann. Das ist
              nicht der Fall. Jedes Land hat eine eigene Gebärdensprache,
              manchmal sogar zwei oder drei verschiedene. In Deutschland gibt es
              die deutsche Gebärdensprache, aber auch unterschiedliche
              Gebärdendialekte. Taube Menschen, die im Ausland auf andere
              gebärdensprachige Menschen treffen, stoßen auch auf
              Kommunikationsschwierigkeiten und Missverständnisse. Dennoch,
              wegen der Ausdrucksform mit viel Mimik ist die Kommunikation eher
              möglich als bei Lautsprachen, aber richtig in die Tiefe gehen und
              über alles kommunizieren kann man auch mit Menschen, die eine
              andere Gebärdensprache verwenden, nicht. Darum betone ich noch
              einmal: Gebärdensprache ist nicht international.
            </Paragraph>

            <Paragraph>
              Gibt es Silben in Gebärdensprache? Um damit auf Hölderlin Bezug zu
              nehmen: Um Gedichte schreiben zu können, braucht man Silben. Alle
              Sprachen haben Silben, und wenn ich behaupte, dass Gebärdensprache
              eine natürliche Sprache ist, muss sie auch Silben haben. Das ist
              natürlich der Fall. Aber was sind Silben in Gebärdensprache?
            </Paragraph>

            <Paragraph>
              Dafür müssen Sie zunächst einmal alles, was Sie über Lautsprache
              wissen, außer Acht lassen und Hören und Sprechen ausblenden. Wir
              betrachten jetzt Sprache sozusagen als Objekt, das wir untersuchen
              müssen. Wir versuchen herauszufinden, wie Sprache aufgebaut ist,
              wie ihre Strukturen sind. So kann man Silben objektiv erkennen.
            </Paragraph>

            <Paragraph>
              Silben in Gebärdensprachen entsprechen Silben in Lautsprachen.
              Eine Silbe wird folgendermaßen definiert: Sie hat einen
              Silbenanfang, einen Silbengipfel und ein Silbenende. Der
              Silbengipfel wird definiert durch maximale Wahrnehmbarkeit. In
              Lautsprache sind Vokale, also die Laute /a/, /e/, /i/, /o/, /u/
              maximal wahrnehmbar. Das Gleiche gilt für Gebärdensprache. Dort
              ist Bewegung der Silbengipfel, also maximal wahrnehmbar. Ein
              Beispiel ist die Gebärde für VATER: Der Silbenanfang ist an der
              Stirn, die Bewegung (Silbengipfel) geht zum Kinn (Silbenende). Es
              gibt genau wie in Lautsprache noch verschiedene Silbenpositionen,
              aber das wollen wir hier nicht vertiefen, wir heben uns ein wenig
              davon für spätere linguistische Beiträge auf.
            </Paragraph>

            <Paragraph>
              Wie ich schon sagte, ist Gebärdensprache eine Sprache. Nun stellt
              sich die Frage, ob Pantomime auch eine Sprache ist. Das ist sie
              nicht, weil sie keine Regeln und Beschränkungen hat, während dies
              bei Gebärdensprache der Fall ist. Gebärdensprache hat eine
              bestimmte Menge an Möglichkeiten, Gebärden zu bilden. Eine Gebärde
              kann beispielsweise an der Stirn, am Brustbein, am linken oder
              rechten Oberarm beginnen oder im neutralen Gebärdenraum, wie z.B.
              bei der Gebärde für FAHREN oder UMZIEHEN – diese Gebärden werden
              im Bereich vor dem Oberkörper ausgeführt. Gebärden werden also
              innerhalb einer gedachten Halbkugel vor dem Oberkörper und Kopf
              ausgeführt, nicht an den Beinen, nur innerhalb dieser gedachten
              Halbkugel.
            </Paragraph>
            <Paragraph>
              Pantomime nutzt den gesamten Körper. Wenn ein Pantomime bspw. das
              Laufen darstellen will, bewegt er die Beine, als würde er laufen,
              er bildet also die Wirklichkeit ab. In Gebärdensprache, das habe
              ich eben bei der Beschreibung der Pantomime gezeigt, codieren die
              Hände, in diesem Fall speziell die Zeigefinger, die sich
              bewegenden Beine. Gebärdensprache ist also eine Sprache, während
              Pantomime die Wirklichkeit abbildet. Bei Pantomime ist der
              Wiedererkennungswert hoch, die Zuschauer verstehen die Intention
              des Pantomimen, während Sie meine gebärdete Beinbewegung ohne
              vorherige Erläuterung sehr wahrscheinlich nicht verstanden, also
              nicht wiedererkannt hätten.
            </Paragraph>
            <Paragraph>
              Für Sprache ist charakteristisch, dass sie Regeln und
              Beschränkungen hat. Ein neugeborenes Baby, das hörend auf die Welt
              kommt, kann alle sprachlichen Laute, die es gibt, sprechen und
              verstehen, na ja, theoretisch, Babys können ja nicht sprechen,
              aber theoretisch gesehen. In den ersten zwei Lebenswochen können
              Babys alle sprachlichen Laute wahrnehmen. Wenn Eltern mit ihnen
              sprechen, ›verringert‹ sich die Fähigkeit der Lautwahrnehmung auf
              die Laute, die in der Muttersprache relevant sind. Die
              Wahrnehmungsfähigkeit der anderen Laute verschwindet dann wieder.
              In Gebärdensprache ist es vergleichbar: Es gibt sehr viele
              Möglichkeiten, Gebärden zu bilden mit Handformen, Bewegungen etc.
              Gebärden die Eltern mit dem Baby und verwenden dabei z.B.
              bestimmte Handformen, erwirbt das Kind u.a. die für die
              Gebärdensprache (Muttersprache) relevanten Handformen und die
              anderen verschwinden wieder.
            </Paragraph>
            <Paragraph>
              Ich möchte es an einem Beispiel verdeutlichen - das Beispiel
              erntet immer viele Lacher, denn es ist in Deutschland auch von
              ›den Hörenden‹ ohne große Erklärung zu verstehen. In der Deutschen
              Gebärdensprache gibt es eine bestimmte Menge an Handformen. In der
              Amerikanischen Gebärdensprache z.B. gibt es eine Handform, bei der
              die Hand geschlossen ist und nur Zeigefinger und kleiner Finger
              abgespreizt sind wie bei der Gebärde CAMP. In der Deutschen
              Gebärdensprache gibt es diese Handform nicht, wir benutzen es auch
              als Lehngebärde, aber es gibt keine anderen Gebärden in der
              Deutschen Gebärdensprache mit dieser speziellen Handform.
            </Paragraph>
            <Paragraph>
              In der Japanischen Gebärdensprache gibt es die Handform mit dem
              ausgestreckten Mittelfinger. Für Deutsche ist das eine
              Erheiterung, da dies unter den Hörenden eine gewisse Bedeutung
              hat. Gebärdensprachige Menschen nutzen diese rüde Geste auch. Aber
              in der Menge der Handformen kommt diese Mittelfinger-Handform in
              der Deutschen Gebärdensprache nicht vor. In der Japanischen
              Gebärdensprache ist die Mittelfinger-Handform Teil der Menge der
              Handformen, in der Deutschen Gebärdensprache nicht. Genauso ist es
              mit (Deutschen) Lautsprache: bestimmte Laute kommen nicht vor und
              werden nicht benutzt. Wenn man dann eine Fremdsprache lernt, hat
              man große Mühe die Laute, die es im Deutschen nicht gibt, richtig
              zu lernen. Bei Reisen in das Land, dessen Sprache man gelernt hat,
              wird man schnell als Deutscher identifiziert, weil man am Akzent
              erkennbar ist.
            </Paragraph>

            <Heading as="h2" level={4}>
              Quizfragen
            </Heading>
            <Stack as="ol" space={6}>
              <StepsBox step={1}>
                <Paragraph>Ist Gebärdensprache international?</Paragraph>
              </StepsBox>
              <StepsBox step={2}>
                <Paragraph>
                  Was ist der Unterschied zwischen Gebärdensprache und
                  Pantomime?
                </Paragraph>
              </StepsBox>
              <StepsBox step={3}>
                <Paragraph>Gibt es Silben in der Gebärdensprache?</Paragraph>
              </StepsBox>
            </Stack>

            <AttributionLiland />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default EinfuehrungDGS
